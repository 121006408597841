import { useState, useEffect, useRef } from 'react'
import useTranslation from 'next-translate/useTranslation'
import styles from './timer.module.scss'
import css from '../GDP/propertyCard.module.scss';
import moment from 'moment';
import { getDateMonthConverted } from '@/utils/utils';
import IconoGraphy from '@/components/Common/IconoGraphy';
import { convertSecondsToDhms } from '@/utils/auctionUtils';
import { useAuctionProvider } from '@/lib/AuctionProvider';

/**
 * This component represents a countdown timer.
 * @param {string} leftOverServerMs - Server EPOCH Time in ms
 * @param {string} leftOverMs - Time left to start/end from server EPOCH time in ms.
 * @param {Function} refetchData - Optional. A function to refetch data when the countdown reaches zero.
 */
const Timer = ({ refetchData = null, currentAuctionStatus = '', isAuctionCard = false, gdpTimer = false, setShowTimerLabel = null,
  leftOverServerMs, leftOverMs, propertyInfo='', isSocketDisconnected=''}) => {
  const [time, setTime] = useState(0)
  const { t, lang } = useTranslation('translations-auction')
  const timerIcon = currentAuctionStatus === 'past' ? 'icon-calendar' : 'icon-asset-timer'
  const timerFontSize = 'f_24'
  const timerGap = (time <= 0 || currentAuctionStatus === 'past') ? css.timerGapSection : ''
  const intervalRef = useRef(null); 
  const {currentEpoch} = useAuctionProvider()
  const timerLessThanZero = (time <= 0 || isNaN(time))

  useEffect(() => {
    const startTimer = async () => {
      const currentTime = new Date().getTime()
      if (currentEpoch === null) {
        // Handle error case where currentEpoch couldn't be fetched
        return;
      }
      const temp = leftOverServerMs - (currentTime - leftOverMs - currentEpoch);
      let timeLeft = temp / 1000;
      setTime(timeLeft);

      intervalRef.current = setInterval(() => {
        timeLeft--;
        setTime(timeLeft);

        if (timeLeft > 0) {
          setTime(timeLeft);
        } else {
          if (setShowTimerLabel) {
            setShowTimerLabel(false);
          }
          setTime(0);
          clearInterval(intervalRef.current);

          if (refetchData) {
            refetchData();
            return;
          }
        }
      }, 1000);
    };

    startTimer();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [leftOverMs, leftOverServerMs, currentEpoch]);

  const formatTime = (seconds) => {
    if(seconds === 0) return '0'
    const { days, hours, minutes, remainingSeconds } = convertSecondsToDhms(seconds);
    const formattedTimeDay = `${days}${lang === 'ar' ? ' ' : ''}${t('time.d')} `
    const formattedTimeHour = `${hours}${lang === 'ar' ? ' ' : ''}${t('time.h')} `
    const formattedTimeMin = `${minutes}${lang === 'ar' ? ' ' : ''}${t('time.m')} `
    const formattedTimeSec = `${remainingSeconds}${lang === 'ar' ? ' ' : ''}${t('time.s')}`

    let formattedTime = ''
    if (days > 0) {
      formattedTime += formattedTimeDay
      // Include hours even if hours is 0
      formattedTime += formattedTimeHour
    }
    else if (hours > 0) {
      formattedTime += formattedTimeHour
    }
    if (minutes >= 0 || (days > 0 && hours === 0)) {
      formattedTime += formattedTimeMin
    }
    formattedTime += formattedTimeSec
    return formattedTime.trim()

  }

  const gdpTimerClassName = !isAuctionCard && styles.detailPageText 

  const renderTimeComponent = (value, label) => {
    if (value < 10) {
      value = `0${value}`
    }
    return <div className={css.innerWrap}>
      <span>{value} </span>
      <>{label} </>
    </div>
  }
  
  const renderCountTimer = (seconds) => {
    const { days, hours, minutes, remainingSeconds } = convertSecondsToDhms(seconds);
    const dayText = days > 1 ? t('groupAssetCard.days') : t('groupAssetCard.day')
    const hourText = hours > 1 ? t('groupAssetCard.hours') : t('groupAssetCard.hour')
    const minuteText = minutes > 1 ? t('groupAssetCard.minutes') : t('groupAssetCard.minute')
    const secText = remainingSeconds > 1 ? t('groupAssetCard.seconds') : t('groupAssetCard.second')
    return (
      <>
        {days > 0 && renderTimeComponent(days, dayText)}
        {(hours > 0 || days > 0) && renderTimeComponent(hours, hourText)}
        {renderTimeComponent(minutes, minuteText)}
        {renderTimeComponent(remainingSeconds, secText)}
      </>
    );
  };

  const auctionEndingText = () => {
    if (timerLessThanZero && currentAuctionStatus) {
      let auctionEnding ;
      const timerComma = lang === 'en' ? ',' : '،'
      const endTimer = getDateMonthConverted(t, moment(propertyInfo?.endDate).format(`DD MMM${timerComma} YYYY`))
        if (currentAuctionStatus === 'past') {
          auctionEnding = <div className={css.pastTimer}> {`${t('groupAssetCard.auctionEndOn')} ${endTimer}`} </div>
        } else if (currentAuctionStatus === 'live') {
          auctionEnding = <div className={css.pastTimer}> {t('auctionGroupDetail.auctionClosingSoon')}</div>
        } else {
          auctionEnding = <div className={css.pastTimer}> {t('auctionGroupDetail.auctionStarting')}</div>
        }
        return auctionEnding;
      }
  }

  if (timerLessThanZero && currentAuctionStatus && currentAuctionStatus !== 'past' && !propertyInfo) {
     return (
        <div className={gdpTimer ? styles.gdpTime : gdpTimerClassName}>
          {currentAuctionStatus === 'live'
            ? t('auctionGroupDetail.auctionClosingSoon')
            : t('auctionGroupDetail.auctionStarting')}
        </div>
      )
  }

  const gdpTimerText = () => {
    if(isNaN(time) && currentAuctionStatus === 'past') {
      return auctionEndingText()
    } 
    return time <= 0 ? auctionEndingText() : renderCountTimer(time) 
  }

  const isgdpSkeletonAvaiable = isSocketDisconnected ? css.gdpSkeleton : ''

  return (
    propertyInfo ? (
      <div className={`${css.timerMainSection} ${isgdpSkeletonAvaiable}`}>
        {!isSocketDisconnected && (
          <div className={`${css.timerSection} ${timerGap}`}>
            <div className={css.innerWrap}>
              <IconoGraphy iconClass={timerIcon} iconColor="color_gray_light" fontSize={timerFontSize} />
            </div>
            {gdpTimerText()}
          </div>
        )}
      </div>
    ) : (
      <div key={leftOverMs}>{formatTime(Number(time))}</div>
    )
  );
}

export default Timer
